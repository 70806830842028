import Permissions from './Permissions';

export default function () {
  let permissions = Permissions();
  return {
    clients: {
      phone: [permissions.CLIENT_READ_CONTACT_IN_LIST],
      mobile: [permissions.CLIENT_READ_CONTACT_IN_LIST],
      email: [permissions.CLIENT_READ_CONTACT_IN_LIST],
      email2: [permissions.CLIENT_READ_CONTACT_IN_LIST],
      companyClient: ['DISABLED'],
      client2UserLoggers: ['DISABLED'],
      active: ['DISABLED'],
    },
    users: {},
    affiliates: {},
    smtps: {},
    mailTemplates: {},
    monetaries: {},
    kyc: {},
    symbol: {},
    symbolGroup: {},
    closedTrades: {},
    trades: {},
    clientStatus: {},
    userActivity: {},
    affiliateDashboard: {},
  };
}
