<template>
  <b-modal no-fade v-model="dialog.show" size="lg" :ref="`columns-modal-${uniqueId}`" :id="`modal-columns-${uniqueId}`"
           :hide-footer="true" centered title="Columns"
           no-close-on-backdrop
  >
    <div class="position-relative columns-modal-container">
      <div class="overflow-y-scroll" style="max-height: 600px; overflow-x: hidden">
        <draggable v-model="columns" group="columns">
          <transition-group >
            <b-list-group-item style="background-color: transparent!important;" v-for="element in columns" :key="element.key" v-if="!element.locked"
                               class="d-flex justify-content-between align-items-center"
            >
              <span>{{ $t(`columns.${element.label || element.key+"?"}`) }}</span>
              <b-form-checkbox v-model="element.enable" name="check-button" switch/>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </div>
      <div class="text-center">
        <b-row>
          <b-col cols="6">
            <b-button block size="md" variant="warning" @click="reset">{{$t('operations.resetDefault')}}</b-button>
          </b-col>
          <b-col cols="6">
            <b-button block size="md" variant="primary" @click="save">{{$t('operations.save')}}</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import draggable from 'vuedraggable'
import ColumnsPermissions from '@/model/ColumnsPermissions'
import mixinPermissions from '@/components/mixin/mixinPermissions'
import mixinBase from '@/components/mixin/mixinBase'
import { toObject } from '@/model/Utils'

export default {
  name: 'columnsModal',
  components: { draggable, },
  mixins: [mixinBase, mixinPermissions],
  props: {
    value: {
      required: true,
      default: () => ([])
    },
    columnsNames: {
      required: true,
      default: () => ([])
    },
    columnsDefault: {
      required: false,
      default: () => ([])
    },
  },
  watch: {
    columnsNames: {
      handler: function (val) {
        this.loadColumns()
        if (this.value.length <= 0) {
          this.save()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    columns: [],
    columnsPermissions: ColumnsPermissions(),
  }),
  created() {
    this.uniqueId = this._uid
  },
  computed:{
    sessionKey() {
      return this.$route.meta.pageKey ? `${this.$route.name}::${this.$route.meta.pageKey}-columns` : `${this.$route.name}-columns`
    },
  },
  methods: {
    showModal() {
      this.dialog.show = true
    },
    getColumnsFromStorage() {
      try {
        const columns = localStorage.getItem(this.sessionKey)
        let cache = JSON.parse(columns)
        if (Array.isArray(cache) && cache.length > 0) {
          return cache
        }
      } catch (e) {
      }
      // console.log("getColumnsFromStorage, geting from default", this.columnsDefault)
      if(Array.isArray(this.columnsDefault) && this.columnsDefault.length > 0){
        return (this.columnsDefault || []).map((column) => ({ key: column, locked: false, enable: true}))
      }else {
        return (this.columnsNames || []).map((column) => ({ key: column, locked: false, enable: true}))
      }

    },
    loadColumns() {
      let $this = this;
      let storageColumnsMap = toObject(this.getColumnsFromStorage(), 'key');
      let columns = this.columnsNames.map((column) => $this.getColumns(storageColumnsMap, column)).filter(c => c !== null);
      if(this.columnsPermissions[this.sessionKey] != null) {
        columns.forEach((column) => $this.setColumnPermissions(column));
      }else {
        console.warn('No permissions found for sessionKey: ', this.sessionKey)
      }
      // console.log("getColumnsFromStorage", {columns, storageColumnsMap})
      this.columns = columns
      this.refreshTables();
    },
    getColumns(storageColumnsMap, column) {
      let result = this.getColumnBasic(column)
      if (storageColumnsMap[result.key]) {
        return ({ key: result.key, label: result.label, locked: storageColumnsMap[result.key].locked || result.locked, enable: storageColumnsMap[result.key].enable || result.enable})
      } else {
        return result
      }
    },
    getColumnBasic(column){
      if(column instanceof String || typeof column === 'string'){
        let label = column.includes(".") ? column.substring(column.lastIndexOf(".") + 1) : column;
        return { key: column, label, locked: false, enable: false}
      }else {
        return { key: column.key, label: column.label, locked: column.locked || false, enable: column.enable || false}
      }
    },
    setColumnPermissions(column){
      // console.log('this.sessionKey : ',this.sessionKey)
      let $this = this;
      let columnPermissions = this.columnsPermissions[this.sessionKey][column.key]
      if (!Array.isArray(columnPermissions)) {
        column.locked = false;
        return;
      }
      column.locked = !columnPermissions.map(p=>$this.hasPermission(p)).reduce((a, b) => a && b, true);
      if(column.locked){
        column.enable = false
      }
    },
    save() {
      let columns = JSON.stringify(this.columns)
      this.$emit('input',  JSON.parse(columns))
      localStorage.setItem(this.sessionKey, columns)
      this.dialog.show = false
    },
    reset() {
      localStorage.removeItem(this.sessionKey);
      this.loadColumns()
    }
  }
}
</script>

<style lang="scss">
.columns-modal-container{
  .list-group-item{
    cursor: grab;
    &:active{
      cursor: grabbing;
    }
  }
}
</style>
